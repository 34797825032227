import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { useCounterpart } from "../../../context/language.context";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("connection.password.required")
    .min(6, "connection.password.requirement"),
  confirmationPassword: yup
    .string()
    .required("connection.password.confirmation-required")
    .oneOf(
      [yup.ref("password"), null],
      "connection.password.confirmation-error"
    ),
});

const Form = ({ onSubmit, isLoading }) => {
  const { counterpart } = useCounterpart();
  const initialValues = {
    password: "",
    confirmationPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {counterpart("connection.reset-password.title")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.password.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.password.title")} *`}
              variant="outlined"
              name="password"
              fullWidth
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={
                formik.touched.password &&
                formik.errors.password &&
                counterpart(formik.errors.password)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.password.confirm")}
            </Typography>
            <TextField
              label={`${counterpart("connection.password.confirmation")} *`}
              variant="outlined"
              name="confirmationPassword"
              type="password"
              fullWidth
              value={formik.values.confirmationPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmationPassword &&
                Boolean(formik.errors.confirmationPassword)
              }
              helperText={
                formik.touched.confirmationPassword &&
                formik.errors.confirmationPassword &&
                counterpart(formik.errors.confirmationPassword)
              }
            />
          </Grid>
          <Grid item container xs={12}>
            {/* <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="space-between"
              width={1}
              maxWidth={600}
              margin="0 auto"
            > */}
            {/* <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size="large"
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    history.push(ROUTES.LOGIN);
                  }}
                >
                  {counterpart("connection.back-to-login")}
                </Button>
              </Box> */}
            <Button
              color="secondary"
              size="large"
              variant="contained"
              type="submit"
              sx={{ width: "100%" }}
            >
              {isLoading ? (
                <Loading />
              ) : (
                counterpart("connection.reset-password.title")
              )}
            </Button>
            {/* </Box> */}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

Form.defaultProps = {
  isLoading: false,
};

export default Form;
