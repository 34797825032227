import styled from "@emotion/styled";
import Select from "@mui/material/Select";

export const CustomSelect = styled(Select)`
  margin-top: 5px;
  fieldset {
    border: 0;
  }
  img {
    height: 13px !important;
    width: 20px !important;
  }
  svg {
    color: black !important;
  }
  .MuiSelect-select {
    padding: 0px 30px 0px 0px !important;
  }
  .MuiPaper-root {
    background-color: #061a2a !important;
  }
`;
