/* eslint-disable arrow-body-style */
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { CustomSelect } from "./style";
import fr from "../../assets/flags/fr.png";
import en from "../../assets/flags/en.png";
import { useCounterpart } from "../../context/language.context";

const FlagContent = () => {
  const { locale, handleSetLocale } = useCounterpart();

  const handleChangeLang = (e) => {
    handleSetLocale(e.target.value);
  };

  return (
    <CustomSelect
      labelId="simple-select-standard-label"
      id="simple-select-standard"
      value={locale || "fr"}
      onChange={handleChangeLang}
      variant="outlined"
      sx={{ height: "15px", padding: "0px" }}
    >
      <MenuItem value="fr">
        <img src={fr} alt="fr" width={25} />
      </MenuItem>
      <MenuItem value="en">
        <img src={en} alt="en" width={25} />
      </MenuItem>
    </CustomSelect>
  );
};
export default FlagContent;
