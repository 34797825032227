import Container from "@mui/material/Container";
import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { Flags, LoginContainer } from "./style";
import { useCounterpart } from "../../context/language.context";
import FlagContent from "../FlagContent";

const ConnectionLayout = (props) => {
  const { children, maxWidth, styles } = props;
  const { locale } = useCounterpart();
  return (
    <LoginContainer style={styles}>
      <Container
        sx={{
          height: "100%",
        }}
      >
        <Stack
          sx={{
            height: "100%",
          }}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            elevation={24}
            sx={{
              maxWidth: maxWidth || 400,
              padding: 5,
              position: "relative",
            }}
          >
            <Flags>
              <FlagContent />
            </Flags>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
              mb={2}
            >
              <Link href={process.env.REACT_APP_FRONT}>
                <img
                  src={`/logo-${locale}.svg`}
                  alt="Jurismediation"
                  width={260}
                />
              </Link>
            </Stack>
            {children}
          </Paper>
        </Stack>
      </Container>
    </LoginContainer>
  );
};

ConnectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number.isRequired,
  styles: PropTypes.string,
};

ConnectionLayout.defaultProps = {
  styles: null,
};

export default ConnectionLayout;
