import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React from "react";
import { Link as LinkReact } from "react-router-dom";
import * as yup from "yup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Loading from "../../../components/Loading";
import { ContentCheckbox } from "./style";
import { goToOtherUrl } from "../../../helpers/utils";
import { ROUTES } from "../../../constants/routes";
import { getCookie } from "../../../helpers/cookie";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    // .min(2, "Please enter a valid name")
    // .max(50, "Please enter a valid name")
    .required("connection.firstname.required"),
  lastName: yup
    .string()
    .trim()
    // .min(2, "Please enter a valid name")
    // .max(50, "Please enter a valid name")
    .required("connection.lastname.required"),
  email: yup
    .string()
    .trim()
    .email("connection.email.invalid")
    .required("connection.email.required"),
  password: yup
    .string()
    .required("connection.password.required")
    .min(6, "connection.password.requirement"),
  confirmationPassword: yup
    .string()
    .required("connection.password.confirmation-required")
    .oneOf(
      [yup.ref("password"), null],
      "connection.password.confirmation-error"
    ),
});

const Form = ({
  isLoading,
  onSubmit,
  referrer,
  counterpart,
  setIsAccepted,
  isAccepted,
  isNewsLetterAccepted,
  setNewsLetterAccepted,
}) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmationPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          textAlign="center"
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {counterpart("menus.create-account")}
        </Typography>
        <Typography textAlign="center" color="text.secondary">
          {counterpart("connection.signup.fill-out")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.firstname.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.firstname.title")} *`}
              variant="outlined"
              name="firstName"
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName &&
                formik.errors.firstName &&
                counterpart(formik.errors.firstName)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.lastname.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.lastname.title")} *`}
              variant="outlined"
              name="lastName"
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={
                formik.touched.lastName &&
                formik.errors.lastName &&
                counterpart(formik.errors.lastName)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.email.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.email.title")} *`}
              variant="outlined"
              name="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email &&
                formik.errors.email &&
                counterpart(formik.errors.email)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.password.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.password.title")} *`}
              variant="outlined"
              name="password"
              type="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={
                formik.touched.password &&
                formik.errors.password &&
                counterpart(formik.errors.password)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.password.confirm")}
            </Typography>
            <TextField
              label={`${counterpart("connection.password.confirmation")} *`}
              variant="outlined"
              name="confirmationPassword"
              type="password"
              fullWidth
              value={formik.values.confirmationPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmationPassword &&
                Boolean(formik.errors.confirmationPassword)
              }
              helperText={
                formik.touched.confirmationPassword &&
                formik.errors.confirmationPassword &&
                counterpart(formik.errors.confirmationPassword)
              }
            />
          </Grid>
          <ContentCheckbox item container xs={12}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={isNewsLetterAccepted}
                    onChange={(event) =>
                      setNewsLetterAccepted(event.target.checked)
                    }
                  />
                }
                label={counterpart("connection.signup.newsletter")}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={isAccepted}
                      onChange={(event) => setIsAccepted(event.target.checked)}
                    />
                  }
                  label={`${counterpart("connection.signup.accept")}`}
                />
                <Box
                  component={Link}
                  onClick={() => {
                    goToOtherUrl(
                      ROUTES.TERMS?.[getCookie("NEXT_LOCALE") || "fr"]
                    );
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {counterpart("connection.signup.cg")}
                </Box>
              </Box>
            </Grid>
          </ContentCheckbox>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="space-between"
              width={1}
              maxWidth={600}
              margin="0 auto"
            >
              <Button
                color="secondary"
                size="large"
                variant="contained"
                disabled={!isAccepted}
                fullWidth
                type="submit"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  counterpart("connection.signup.title")
                )}
              </Button>
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="center"
              width={1}
              maxWidth={600}
              margin="0 auto"
            >
              <Box marginBottom={{ xs: 1, sm: 0 }} textAlign="center">
                <Typography variant="subtitle2" textAlign="center">
                  {counterpart("connection.have-account")}{" "}
                  <Link
                    component={LinkReact}
                    color="primary"
                    to={referrer ? `/login${referrer}` : "/login"}
                    underline="none"
                  >
                    {counterpart("connection.login.title")}.
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              align="center"
            >
              {counterpart("connection.signup.accept-cg.part-one")}{" "}
              <Link
                component={LinkReact}
                color="primary"
                to="/company-terms"
                underline="none"
              >
                {counterpart("connection.signup.accept-cg.part-two")}
              </Link>
            </Typography>
          </Grid> */}
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  counterpart: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAccepted: PropTypes.bool.isRequired,
  setIsAccepted: PropTypes.func.isRequired,
  isNewsLetterAccepted: PropTypes.bool.isRequired,
  setNewsLetterAccepted: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  referrer: PropTypes.string,
};

Form.defaultProps = {
  isLoading: false,
  referrer: null,
};

export default Form;
