import Box from "@mui/material/Box";
import styled from "styled-components";

export const LoginContainer = styled("div")({
  backgroundColor: "#071B29",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Flags = styled(Box)`
  position: absolute;
  right: 9px;
  top: 9px;
`;
