import { ENDPOINTS } from "../constants/endpoints";
import { ROUTES } from "../constants/routes";
// import { ROUTES } from "../constants/routes";
import axios from "../helpers/axios";
import { setCookie } from "../helpers/cookie";
import { handleResponse } from "../helpers/services";
import { setToken } from "../helpers/utils";

class SessionService {
  async login(payload) {
    return axios
      .post(`${ENDPOINTS.USER}/Authenticate`, payload)
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        setCookie("token", responseData.token, 30);
        setToken(responseData.token);
        // history.push(payload.referrer || ROUTES.ACCOUNT_INFORMATIONS);
        return response;
      });
  }

  async register(payload) {
    return axios
      .post(`${ENDPOINTS.USER}/Register`, payload)
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        setCookie("token", responseData.token, 30);
        setToken(responseData.token);
        // history.push(payload.referrer || ROUTES.ACCOUNT_INFORMATIONS);
        return response;
      });
  }

  async get(parameters) {
    return axios
      .get(`${ENDPOINTS.USER}/Current`, parameters)
      .then(handleResponse)
      .then((response) => response);
  }

  async forgottenPassword({ email }) {
    return axios
      .put(`${ENDPOINTS.USER}/ForgottenPassword`, { email })
      .then(handleResponse)
      .then((response) => response);
  }

  async resetPassword({ token, password }, history) {
    return axios
      .put(`${ENDPOINTS.USER}/ResetPassword`, { token, password })
      .then(handleResponse)
      .then(() => {
        history.push(ROUTES.LOGIN);
      });
  }
}

export const sessionService = new SessionService();
