import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Form from "./Form";
import { login } from "../../actions/session.actions";
import ConnectionLayout from "../../components/ConnectionLayout";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const onSubmit = async (values) => {
    setIsLoading(true);
    await Promise.all([dispatch(login(values))]).finally(() => {
      setIsLoading(false);
    });
    // if (res?.token) {
    //   const newWindow = window.open(process.env.REACT_APP_FRONT, "_self");
    //   if (newWindow) newWindow.opener = null;
    // }
  };

  return (
    <ConnectionLayout maxWidth={400}>
      <Form
        onSubmit={onSubmit}
        isLoading={isLoading}
        referrer={location?.search}
      />
    </ConnectionLayout>
  );
};

export default LoginPage;
