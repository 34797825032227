import { SESSION } from "../constants/actions";
import { action } from "../helpers/action";
import counterpart from "../helpers/counterpart";
import { sessionService } from "../services/session.service";

export const login = (parameters) =>
  action(
    () => sessionService.login(parameters),
    SESSION.LOGIN
    // counterpart("message.login")
  );

export const register = (parameters) =>
  action(
    () => sessionService.register(parameters),
    SESSION.REGISTER
    // counterpart("message.register-success")
  );

export const getUser = (parameters) =>
  action(() => sessionService.get(parameters), SESSION.GET);

export const resetPassword = (parameters, history) =>
  action(
    () => sessionService.resetPassword(parameters, history),
    SESSION.RESET_PASSWORD
  );

export const forgottenPassword = (parameters) =>
  action(
    () => sessionService.forgottenPassword(parameters),
    SESSION.FORGOT_PASSWORD,
    counterpart("connection.email-sending", { email: parameters.email })
  );

export const logout = () => action(null, SESSION.LOGOUT);
