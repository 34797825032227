import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const ContentCheckbox = styled(Grid)`
  label:first-child {
    margin-right: 6px;
  }
  span,
  a {
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.57;
  }
`;
