/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { getCookie } from "../../helpers/cookie";
import { convertSearchToObject, getKeyRoute } from "../../helpers/utils";
import { ROUTES } from "../../constants/routes";

const PublicRoute = (props) => {
  const { component: Component, ...rest } = props;
  const user = useSelector((state) => state.session);

  if (user && getCookie("token")) {
    const item = convertSearchToObject(props?.location?.search);
    const newWindow = window.open(
      item?.referrer
        ? `${process.env.REACT_APP_FRONT}/${getCookie("NEXT_LOCALE") || "fr"}/${
            ROUTES?.[getKeyRoute(item.referrer)]?.[
              getCookie("NEXT_LOCALE") || "fr"
            ]
          }#${item.type}`
        : `${process.env.REACT_APP_FRONT}/${getCookie("NEXT_LOCALE") || "fr"}`,
      "_self"
    );
    if (newWindow) newWindow.opener = null;
  }

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default PublicRoute;
