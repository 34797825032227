/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../actions/session.actions";
import { ROUTES } from "../../constants/routes";
import Background from "../../assets/backgrounds/1.jpg";
import ConnectionLayout from "../../components/ConnectionLayout";
import Form from "./Form";

export const ResetPasswordPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = window.location.search.replace("?token=", "");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      history.push(ROUTES.HOME);
    }
  }, [token, history]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { password: passwordValue } = values;
    await Promise.all([
      dispatch(resetPassword({ token, password: passwordValue }, history)),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <ConnectionLayout imageUrl={Background}>
      <Form onSubmit={onSubmit} isLoading={isLoading} />
    </ConnectionLayout>
  );
};
