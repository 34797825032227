import { createTheme } from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material";
import shadows from "./shadows";
import { light } from "./palette";

const fontFamily = [
  "'Outfit', sans-serif",
  "-apple-system",
  "BlinkMacSystemFont",
  "'Segoe UI'",
  "Roboto",
  "'Helvetica Neue'",
  "Arial",
  "'Noto Sans'",
  "sans-serif",
  "'Apple Color Emoji'",
  "'Segoe UI Emoji'",
  "'Segoe UI Symbol'",
  "'Noto Color Emoji'",
];

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      ...light,
    },
    shadows: shadows(),
    typography: {
      fontFamily: `${fontFamily.join(",")}`,
      button: {
        textTransform: "none",
        fontWeight: "medium",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "5px",
            fontWeight: 500,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "#53de62",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              color: "#53de62",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused fieldset": {
              borderColor: "#53de62 !important",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#53de62",
            },
          },
        },
      },
    },
  })
);
export default theme;
