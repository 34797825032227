import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useCounterpart } from "../../context/language.context";
// import { getQueryParams } from "../../helpers/utils";
import ConnectionLayout from "../../components/ConnectionLayout";
import Form from "./Form";
import { register } from "../../actions/session.actions";

const RegisterPage = () => {
  const dispatch = useDispatch();
  const { counterpart, locale } = useCounterpart();
  // const { referrer } = getQueryParams();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewsLetterAccepted, setNewsLetterAccepted] = useState(false);
  const location = useLocation();

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { email, password, firstName, lastName } = values;
    await Promise.all([
      dispatch(
        register({
          email,
          password,
          firstName,
          lastName,
          language: locale,
          newsletter: isNewsLetterAccepted,
        })
      ),
    ]).finally(() => {
      setIsLoading(false);
    });

    // if (res?.token) {
    //   const newWindow = window.open(process.env.REACT_APP_FRONT, "_self");
    //   if (newWindow) newWindow.opener = null;
    // }
  };

  return (
    <ConnectionLayout
      maxWidth={600}
      styles={{ paddingTop: "30px", paddingBottom: "30px" }}
    >
      <Form
        isLoading={isLoading}
        onSubmit={onSubmit}
        counterpart={counterpart}
        isAccepted={isAccepted}
        setIsAccepted={setIsAccepted}
        isNewsLetterAccepted={isNewsLetterAccepted}
        setNewsLetterAccepted={setNewsLetterAccepted}
        referrer={location?.search}
      />
    </ConnectionLayout>
  );
};

export default RegisterPage;
