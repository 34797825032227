/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useSnackbar } from "../context/snackbar.context";
import axios from "../helpers/axios";
import counterpart from "../helpers/counterpart";
import { handleResponse } from "../helpers/services";

const withAxiosErrorHandler = (Component) => {
  const Wrapped = ({ ...props }) => {
    const setSnackbar = useSnackbar();
    useEffect(() => {
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          handleResponse(error.response);
          if (error.response.status) {
            setSnackbar({
              isOpen: true,
              message: error.response.data.message
                ? !counterpart(error.response.data.message).includes(
                    "missing translation"
                  )
                  ? counterpart(error.response.data.message)
                  : error.response.data.message
                : counterpart("message.error", {
                    value: error.response.status,
                  }),
              variant: "error",
            });
          } else {
            setSnackbar({
              isOpen: true,
              message: error.message,
              variant: "error",
            });
          }
          return error;
        }
      );
    }, [setSnackbar]);
    return <Component {...props} />;
  };
  return Wrapped;
};

export default withAxiosErrorHandler;
