export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  FORGOTTENPASSWORD: "/forgottenpassword",
  RESETPASSWORD: "/resetpassword",
  TERMS: {
    en: `${process.env.REACT_APP_FRONT}/en/legal-information`,
    fr: `${process.env.REACT_APP_FRONT}/fr/mentions-legales`,
  },
  JOINUS: { fr: "rejoindre-reseau", en: "join-our-network" },
};
