export const SESSION = {
  LOGIN: "session.login",
  LOGOUT: "session.logout",
  GET: "session.get",
  DELETE: "session.delete",
  REGISTER: "session.register",
  RESET_PASSWORD: "session.resetPassword",
  FORGOT_PASSWORD: "session.forgotPassword",
};

export const SNACKBAR = {
  INIT: "snackbar.init",
  UPDATE: "snackbar.update",
};
