/* eslint-disable no-restricted-syntax */
import queryString from "query-string";
import axios from "./axios";
import { ROUTES } from "../constants/routes";

export const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getQueryParams = () => queryString.parse(window.location.search);

export const goToOtherUrl = (link) => {
  const newWindow = window.open(link, "_self");
  if (newWindow) newWindow.opener = null;
};

export const convertSearchToObject = (url) => {
  if (url) {
    const searchParams = new URLSearchParams(url);
    const searchObj = {};

    for (const [key, value] of searchParams.entries()) {
      searchObj[key] = value;
    }

    return searchObj;
  }
  return null;
};

export const getKeyRoute = (link) =>
  Object.keys(ROUTES).find(
    (item) =>
      ROUTES?.[item]?.fr?.includes(link.toLowerCase()) ||
      ROUTES?.[item]?.en?.includes(link.toLowerCase())
  );
