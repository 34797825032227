import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ROUTES } from "./constants/routes";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import { history } from "./helpers/history";
import { getCookie } from "./helpers/cookie";
import { getUser, logout } from "./actions/session.actions";
import PublicRoute from "./components/PublicRoute";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { ForgottenPasswordPage } from "./pages/ForgottenPasswordPage";
import withAxiosErrorHandler from "./hoc/axiosErrorHandler";

const App = () => {
  const dispatch = useDispatch();

  useEffect(async () => {
    if (getCookie("token")) {
      await dispatch(getUser(null));
    } else {
      await dispatch(logout());
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute exact path={ROUTES.HOME} component={LoginPage} />
        <PublicRoute path={ROUTES.LOGIN} component={LoginPage} />
        <PublicRoute path={ROUTES.REGISTER} component={RegisterPage} />
        <PublicRoute
          path={ROUTES.RESETPASSWORD}
          component={ResetPasswordPage}
        />
        <PublicRoute
          path={ROUTES.FORGOTTENPASSWORD}
          component={ForgottenPasswordPage}
        />
        <PublicRoute component={LoginPage} />
      </Switch>
    </Router>
  );
};

export default withAxiosErrorHandler(App);
