import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React from "react";
import { Link as LinkReact, withRouter } from "react-router-dom";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { useCounterpart } from "../../../context/language.context";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("connection.email.invalid")
    .required("connection.email.required"),
  password: yup.string().required("connection.password.required"),
});

const Form = ({ isLoading, onSubmit, referrer }) => {
  const { counterpart } = useCounterpart();
  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={6} marginTop={2}>
        <Typography
          textAlign="center"
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {counterpart("connection.login.title")}
        </Typography>
        <Typography textAlign="center" color="text.secondary">
          {counterpart("connection.login.desc")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.email.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.email.title")} *`}
              variant="outlined"
              name="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email &&
                formik.errors.email &&
                counterpart(formik.errors.email)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="space-between"
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant="subtitle2">
                  {counterpart("connection.password.enter")}
                </Typography>
              </Box>
            </Box>
            <TextField
              label={`${counterpart("connection.password.title")} *`}
              variant="outlined"
              name="password"
              type="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={
                formik.touched.password &&
                formik.errors.password &&
                counterpart(formik.errors.password)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" textAlign="right">
              <Link
                component={LinkReact}
                color="primary"
                to={
                  referrer
                    ? `/forgottenpassword${referrer}`
                    : "/forgottenpassword"
                }
                underline="none"
              >
                {`${counterpart("connection.password.forgot")} ?`}
              </Link>
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="space-between"
              width={1}
              maxWidth={600}
              margin="0 auto"
            >
              <Button
                color="secondary"
                disable={isLoading}
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  counterpart("connection.login.title")
                )}
              </Button>
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="center"
              width={1}
              maxWidth={600}
              margin="0 auto"
            >
              <Box marginBottom={{ xs: 1, sm: 0 }} textAlign="center">
                <Typography variant="subtitle2" textAlign="center">
                  {counterpart("connection.dont-have-account")}{" "}
                  <Link
                    component={LinkReact}
                    color="primary"
                    to={referrer ? `/register${referrer}` : "/register"}
                    underline="none"
                  >
                    {counterpart("connection.signup.here")}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  referrer: PropTypes.string,
};

Form.defaultProps = {
  isLoading: false,
  referrer: null,
};

export default withRouter(Form);
