import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { forgottenPassword } from "../../actions/session.actions";
import ConnectionLayout from "../../components/ConnectionLayout";
import Form from "./Form";

export const ForgottenPasswordPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const onSubmit = async (values) => {
    setIsLoading(true);
    await Promise.all([dispatch(forgottenPassword(values))]).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <ConnectionLayout>
      <Form
        history={history}
        isLoading={isLoading}
        onSubmit={onSubmit}
        referrer={location?.search}
      />
    </ConnectionLayout>
  );
};
