import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { ROUTES } from "../../../constants/routes";
import { useCounterpart } from "../../../context/language.context";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("connection.email.invalid")
    .required("connection.email.required"),
});

const Form = ({ isLoading, onSubmit, history, referrer }) => {
  const { counterpart } = useCounterpart();
  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          textAlign="center"
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          {counterpart("connection.password.forgot")}?
        </Typography>
        <Typography textAlign="center" color="text.secondary">
          {counterpart("connection.forgot-password.desc")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {counterpart("connection.email.enter")}
            </Typography>
            <TextField
              label={`${counterpart("connection.email.title")} *`}
              variant="outlined"
              name="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email &&
                formik.errors.email &&
                counterpart(formik.errors.email)
              }
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent="space-between"
              width={1}
              maxWidth={600}
              margin="0 auto"
            >
              <Box marginBottom={{ xs: 1, sm: 0 }} marginRight={1}>
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    history.push(
                      referrer ? `${ROUTES.LOGIN}${referrer}` : ROUTES.LOGIN
                    );
                  }}
                >
                  {counterpart("connection.back-to-login")}
                </Button>
              </Box>
              <Button
                color="secondary"
                size="large"
                variant="contained"
                type="submit"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  counterpart("connection.forgot-password.send")
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool,
  referrer: PropTypes.string,
};

Form.defaultProps = {
  isLoading: false,
  referrer: null,
};

export default Form;
