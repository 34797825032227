import { combineReducers } from "redux";
import { session } from "./session.reducer";
import { snackbar } from "./snackbar.reducer";

const rootReducer = combineReducers({
  session,
  snackbar,
});

export default rootReducer;
